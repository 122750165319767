<template>
    <div>联系管理员开通权限</div>
</template>

<script>
export default {
    name: "Error403"
}
</script>

<style scoped>

</style>